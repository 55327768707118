import { render, staticRenderFns } from "./compound.vue?vue&type=template&id=8c3b532c&scoped=true&"
import script from "./compound.vue?vue&type=script&lang=js&"
export * from "./compound.vue?vue&type=script&lang=js&"
import style0 from "./compound.vue?vue&type=style&index=0&id=8c3b532c&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c3b532c",
  null
  
)

export default component.exports