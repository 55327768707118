<!--  -->
<template>
  <div class="videoMain">
    <el-card class="box-card" :class="{ frosted_glassed: isActive }">
      <div class="card-top">
        <div class="top-left">
          <span class="card-span">{{ dataList.title }}</span>
          <a class="card-a" href="javascript:;" @click="setFile_List">{{
            dataList.click
          }}</a>
        </div>
        <div class="videoButton">
          <el-button @click="emptyfileList" type="info" class="clearButton"
            >清空示例</el-button
          >
          <el-button type="primary" @click="getrequest">合成视频</el-button>
        </div>
      </div>
      <div class="upload">
        <el-upload
          :action="action"
          class="upload-upload"
          auto-upload:false
          list-type="picture-card"
          :limit="limit"
          :multiple="multiple"
          :file-list="fileList"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :on-change="handleChange"
          :on-exceed="handpicked"
        >
          <span class="upload-text">+添加</span>
          <!-- :action="action" -->
          <!-- :http-request="getrequest" -->
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
      <!-- <div class="annotating">
        <p v-for="(item, index) in dataList.hint" :key="index">
          {{ index + 1 }}、{{ item }}
        </p>
      </div> -->
      <div class="VideoList">
        <span class="VideoList-span">{{ dataList.title1 }}</span>
        <div class="video">
          <div
            class="imgClass"
            v-for="(item, index) in videoListData"
            :key="index"
          >
            <video width="100%" height="100%" controls>
              <source :src="item" type="video/mp4" />
              您的浏览器不支持 video 属性。
            </video>
          </div>
        </div>
      </div>
    </el-card>

    <div class="msg-tipsd" style="display: none" id="msgTip">
      视频正在合成...
    </div>
  </div>
</template>

<script>
import { apiUri } from '../../api/txt';

export default {
  props: ['videoList'],
  data() {
    return {
      dataList: this.videoList,
      dialogImageUrl: '',
      dialogVisible: false,
      multiple: true,
      limit: Number(11),
      videoListData: [],
      action: `${apiUri}/img/imgUpload`,
      fileList: [],
      imgList: [456810596, 755830458, 779658171, 839906251],
      isActive: false,
    };
  },

  mounted() {},

  methods: {
    // 切换实例
    setFile_List() {
      this.fileList = [];
      const randomInt = Math.floor(Math.random() * 4);
      for (let index = 1; index < 9; index++) {
        let _url =
          window.location.origin +
          '/' +
          require(`../../assets/img/video/${this.imgList[randomInt]}/${index}.jpg`);
        let fileName = _url.split('/').pop();
        let type = fileName.split('.').pop();
        this.dataURLtoBlob(_url, (blobData) => {
          let _row = new File([blobData], fileName, { type: 'image/' + type });
          this.fileList.push({
            name: `${index}`,
            url: _url,
            raw: _row,
          });
        });
      }
      // console.log(this.fileList);
    },
    // 清空示例图
    emptyfileList() {
      this.fileList = [];
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    // 上传文件发生改变时
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    // 点击文件列表中已上传的文件
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 上传数量限制行为
    handpicked(files, fileList) {},
    // 上传行为
    getrequest() {
      // this.$message({
      //   message: '功能尚未开放',
      //   type: 'info',
      // });
      // return;
      this.videoListData = [];
      if (this.fileList.length < 5) {
        return this.$message({
          message: '上传图片需大于等于五张',
          type: 'warning',
          offset: window.screen.height / 2,
        });
      }

      // const loading = this.$loading({
      // lock: true,
      // text: "正在获取数据，请等待",
      // spinner: "el-icon-loading",
      // background: "rgba(0, 0, 0, 0.7)",
      // } );

      this.isActive = true;
      document.getElementById('msgTip').style.display = 'block';

      let form = new FormData();
      for (let i = 0; i < this.fileList.length; i++) {
        form.append('files', this.fileList[i].raw);
      }

      this.uploadFormData(form).then((res) => {
        if (res.state === '0x0000') {
          // console.log(res);
          this.videoListData = res.data;
          this.isActive = false;
          document.getElementById('msgTip').style.display = 'none';
          // this.fileList = []
          // loading.close();
          // this.fileList = [];
        } else if (res.state === '0x0008') {
          // this.$message.error(res.message)
          this.$message({
            message: res.message,
            type: 'error',
          });
          this.$emit('logOn');
        } else {
          this.isActive = false;
          document.getElementById('msgTip').style.display = 'none';
          // this.$message.error(res.message);
          this.$message({
            message: res.message,
            type: 'error',
          });
        }
      });
    },
    // 上传文件
    uploadFormData(form) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${apiUri}/video/makeVideo`, true);
        xhr.setRequestHeader(
          'token',
          localStorage.getItem('tokenKey')
            ? localStorage.getItem('tokenKey')
            : '',
        );
        const that = this;
        xhr.onload = function () {
          if (this.status === 200) {
            // console.log("上传成功！");
            const res = JSON.parse(this.response);
            console.log(res);
            if (res.state === '0x0021') {
              that.$message({
                message: res.message,
                type: 'warning',
                duration: 1 * 1000,
                onClose: () => {
                  that.$router.push({
                    path: '/login/relevance',
                    query: { redirect: window.location.href },
                  });
                  return;
                },
              });
            } else {
              resolve(res);
            }
          } else {
            console.error('上传失败！');
          }
        };
        xhr.send(form);
      });
    },

    dataURLtoBlob(dataurl, callback) {
      let xhr = new XMLHttpRequest();
      xhr.open('get', dataurl, true);
      xhr.responseType = 'blob';
      xhr.onload = function (res) {
        if (this.status == 200) {
          var blob = this.response;
          callback(blob);
        }
      };
      xhr.send();
    },
  },

  mounted() {
    this.setFile_List();
  },
};
</script>
<style scoped lang="less">
.videoMain {
  position: relative;
  width: 1280px;
  margin: auto;
  box-sizing: border-box;
  .el-button {
    padding: 14px 16px;
    font-size: 16px;
    font-weight: 400;
  }
  .el-button--info {
    background: #f0f2f5;
    color: #9e9e9e;
    border-color: #f0f2f5;
  }
  /deep/.el-upload-list {
    // justify-content: space-between;
    column-gap: 9px;
    row-gap: 9px;
    // display: flex;
    flex-wrap: wrap;
    .el-upload-list__item {
      margin: 0 9px 9px 0;
      &:nth-child(7n) {
        margin-right: 0;
      }
    }
  }
}
.box-card {
  width: 100%;
  box-sizing: border-box;
  min-height: 540px;
  background: #fff;
  border-radius: 16px;
  margin: auto;
  padding: 10px;
}
.card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-top .top-left .card-span {
  width: 130px;
  height: 16px;
  color: #000108;
  font-weight: 700;
  line-height: 16px;
  font-size: 16px;
  font-family: PingFang-SC-Medium;
}
.card-top .top-left .card-a {
  color: #777aed;
  font-size: 16px;
  line-height: 16px;
  margin-left: 18px;
  font-weight: 700;
}
.box-card .upload {
  width: 100%;
  min-height: 166px;
  box-sizing: content-box;
  // padding: 26px 20px;
  // padding-bottom: 5px;
  margin-top: 24px;
}
.box-card .upload .upload-upload /deep/ .el-upload.el-upload--picture-card {
  width: 166px;
  height: 166px;
  position: relative;
}
.box-card .upload .upload-upload /deep/ .el-upload-list__item.is-success {
  width: 166px;
  height: 166px;
}
.box-card .annotating {
  color: #999999;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 26px;
  font-family: PingFang-SC-Medium;
}
.VideoList {
  margin-bottom: 30px;
  margin-top: 44px;
}
.VideoList .VideoList-span {
  width: 130px;
  font-size: 16px;
  height: 16px;
  color: #000108;
  font-weight: 700;
  line-height: 16px;
}
.VideoList .video {
  width: 100%;
  height: 166px;
  margin-top: 20px;
  display: flex;
}
.VideoList .video .imgClass {
  width: 166px;
  height: 166px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  border-radius: 8px;
}

.VideoList .video .imgClass span {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}

.VideoList .video .imgClass img {
  width: 166px;
  height: 166px;
  border-radius: 8px;
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
}
.VideoList .video .imgClass video {
  border-radius: 8px;
}
/* 遮罩层 */
.frosted_glassed {
  pointer-events: none;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius="3");
}
.msg-tipsd {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -70%);
  padding: 0 32px;
  width: fit-content;
  width: fit-content;
  height: 36px;
  line-height: 36px;
  color: #666;
  background: #fff;
  border-radius: 4px;
}
.upload-text {
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1280px) {
  .videoMain {
    width: 100%;
  }
}
@media (max-width: 990px) {
  .videoMain {
    position: relative;
    width: 95%;
    margin: auto;
  }
  .box-card {
    width: 100%;
    min-height: 540px;
    background: #f9f8fc;
    border-radius: 6px 6px 6px 6px;
    margin: auto;
    padding: 0;
    /* position: relative; */
  }
  .box-card .upload {
    width: 100%;
    min-height: 166px;
    box-sizing: content-box;
    padding: 0px;
    padding-bottom: 5px;
    white-space: nowrap;
    overflow-x: scroll;
  }
  .box-card .upload .upload-upload /deep/ .el-upload.el-upload--picture-card {
    width: 128px;
    height: 128px;
  }
  .box-card .upload .upload-upload /deep/ .el-upload-list__item.is-success {
    width: 128px;
    height: 128px;
  }
  .card-top {
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .videoButton {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .msg-tipsd {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -70%);
    padding: 0 32px;
    width: fit-content;
    width: fit-content;
    height: 36px;
    line-height: 36px;
    color: #666;
    background: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  .box-card .annotating {
    color: #999999;
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 0px;
    font-family: PingFang-SC-Medium;
  }
}
</style>
