<template>
  <div class="page">
    <Viewheader :isHome="false" ref="navheader" />
    <!-- banner图 -->
    <BannerHead :title="bigTitle_1" :desc="smallTitle_1">
      <div class="header-custom" slot="content">
        <img class="banner-bg" />
        <img class="banner1" src="@/assets/img/imgToVideo/banner-1_1.png" />
        <img class="banner1_2" src="@/assets/img/imgToVideo/banner-1_2.png" />
        <img class="banner2" src="@/assets/img/imgToVideo/banner-2_1.png" />
        <img class="banner2_2" src="@/assets/img/imgToVideo/banner-2_2.png" />
        <img class="banner3" src="@/assets/img/imgToVideo/banner-3_1.png" />
        <img class="banner3_2" src="@/assets/img/imgToVideo/banner-3_2.png" />
        <img class="banner4" src="@/assets/img/imgToVideo/banner-4.png" />
        <img class="banner5" src="@/assets/img/imgToVideo/banner-5.png" />
        <img class="banner6" src="@/assets/img/imgToVideo/banner-6.png" />
        <img class="banner7" src="@/assets/img/imgToVideo/banner-7.png" />
      </div>
    </BannerHead>

    <!-- 核心功能 -->
    <div class="core-function">
      <CommonTitle title="核心功能" />
      <ColumuTwo
        class="core-function_main"
        :list="spanList"
        :img="coreImg"
      ></ColumuTwo>
      <img class="core-gift" src="@/assets/img/imgToVideo/core-1.png" alt="" />
    </div>

    <!-- 能力展示 -->
    <div class="similar-main-wrap">
      <div class="similar-main">
        <CommonTitle title="能力展示" :desc="ability.desc" />
        <!-- <Compound :videoList="videoList" @logOn="logOn"></Compound> -->

        <video
          id="similar-video"
          src="https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/1711273531780/similar-video.m4v"
          autoplay="autoplay"
          loop="loop"
          muted
        ></video>

        <div class="copyright">
          <p class="copyright-item">
            ——该应用生成/合成的内容均由“<span>urlVideo视频合成算法</span>”输出，如需应用请注意核查。
          </p>

          <p class="copyright-pact">
            你的
            <el-button type="text" @click="jumpToProtocol(1)"
              >《隐私协议》</el-button
            >及<el-button type="text" @click="jumpToProtocol(3)"
              >《AI服务协议》</el-button
            >
          </p>
        </div>
      </div>
    </div>

    <!-- 差异优势 -->
    <div class="different-advantage-wrap">
      <div class="different-advantage">
        <CommonTitle title="差异优势" theme="light" />
        <img
          class="different-clothes"
          src="@/assets/img/imgToVideo/diff-clothes.png"
        />
        <ColumuTwo
          class="different-advantage_main"
          theme="light"
          :list="diffList"
        ></ColumuTwo>
        <img
          class="diff-1 diff-icon"
          src="@/assets/img/imgToVideo/diff-star1.png"
        />
        <img
          class="diff-2 diff-icon"
          src="@/assets/img/imgToVideo/diff-star2.png"
        />
        <img
          class="diff-3 diff-icon"
          src="@/assets/img/imgToVideo/diff-1.png"
        />
        <img
          class="diff-4 diff-icon"
          src="@/assets/img/imgToVideo/diff-2.png"
        />
        <img
          class="diff-5 diff-icon"
          src="@/assets/img/imgToVideo/diff-3.png"
        />
        <img
          class="diff-6 diff-icon"
          src="@/assets/img/imgToVideo/diff-phone.png"
        />
        <img
          class="diff-video diff-icon"
          src="@/assets/img/imgToVideo/diff-video.png"
        />
        <!-- <img class="diff-7" src="@/assets/img/imgToVideo/diff-star2.png" > -->
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="application-scene">
      <CommonTitle title="应用场景" />
      <ColumuTwo
        class="application-scene_main"
        :list="appliList"
        :img="appImg"
      ></ColumuTwo>
    </div>

    <!-- 尾部 -->
    <Footering />
  </div>
</template>

<script>
import Compound from '../../components/centerPage/compound';
// banner
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import ColumuTwo from './components/ColumuTwo.vue';
import compound from '../../components/centerPage/compound.vue';

// 公用组件
import '../../assets/css/public.css';
import '../../assets/css/imgPublic.css';
import '../../assets/css/commonModule.css';
// 头部
import Viewheader from '../../components/viewheader/viewheader.vue';
// foote组件
import Footering from '@/components/foot/footer.vue';

export default {
  components: {
    Compound,
    BannerHead,
    CommonTitle,
    ColumuTwo,
    compound,
    Viewheader,
    Footering,
  },
  data() {
    return {
      coreImg: require('@/assets/img/imgToVideo/core-2.png'),
      bigTitle_1: '商品素材视频合成',
      smallTitle_1:
        '通过商品的标题、主副图等素材，批量"模板化"商品视频生成更高阶版本，接口对接进行体验',

      // 核心功能
      spanList: [
        {
          title: '<span style="color:#6C38E0;">图片</span>视频合成',
          desc: '支持根据用户上传图片，合成配有音乐的视频。',
        },
        {
          title: '<span style="color:#6C38E0;">商品</span>视频合成',
          desc: '自动挖掘商品信息，例如标题属性评论等核心卖点，配合商品主副图，合成带有特效及音乐的视频。',
        },
        {
          title: '<span style="color:#6C38E0;">自建库</span>视频合成',
          desc: '快速创建专属素材库，视频库、图片库、文本库和音乐库，自动对库中素材进行匹配和剪辑。',
        },
      ],

      // 视频合成

      videoList: {
        title: '上传商品图片素材',
        click: '换一批示例图',
        hint: [
          '支持jpeg、png图，至少上传5张商品图',
          '2.0版本（视频商品卖点语音介绍、视频图文混剪）近期上线',
        ],
        title1: '视频列表',
      },

      // 能力展示
      ability: {
        // desc: `支持jpeg、png图，至少上传5张商品图。2.0版本（视频商品卖点语音介绍、视频图文混剪）近期上线。`,
        desc: ``,
      },

      //   差异优势
      // diffImg: chayi,
      diffList: [
        {
          title: '多元化',
          desc: '输入素材种类选择空间大，生成视频特效丰富。',
        },
        {
          title: '更高效率，更低成本',
          desc: '通过AI技术实现自动化视频合成，无需人工技术要求，通过简单操作，合成具有专业水平的视频内容。',
        },
      ],

      //   应用场景
      appImg: require('@/assets/img/imgToVideo/app-1.png'),
      appliList: [
        {
          title: `电商平台<span style="color:#6C38E0;">多元化</span>商品信息表达`,
          desc: '在电商平台上通过视频将商品的卖点等信息，更直接的传达给用户，提升商品价值感知。同时，各平台也会给予视频商品更多的曝光扶持。',
        },
        {
          title: '媒体视频引流',
          desc: '增加seo页面收录及视频引流量，在FB、ins等社媒平台规模化引流。',
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    logOn() {
      this.$refs.navheader.logOn();
    },

    // 跳转至协议页面
    jumpToProtocol(activeId) {
      window.open(
        `https://aishipgo.com/#/protocol?active=${activeId}`,
        '_blank'
      );
    },
  },
};
</script>

<style lang="less" scoped>
// banner
.header-custom {
  width: 100%;
  margin: auto;
  height: 100%;
  position: absolute;
  background: #f8f5ff;
  left: 50%;
  transform: translate(-50%);
  img {
    position: absolute;
  }
  .banner-bg {
    width: 100%;
    top: 0;
    left: 0;
  }
  @time: 0.7s;
  .banner1 {
    width: 21%;
    bottom: 32%;
    left: 5%;
  }
  .banner1_2 {
    width: 21%;
    bottom: 32%;
    left: 5%;
    opacity: 0;
    animation: fadeIn @time 1 ease-in-out forwards;
  }
  .banner2 {
    width: 22%;
    bottom: 32%;
    left: 27%;
  }
  .banner2_2 {
    width: 22%;
    bottom: 32%;
    opacity: 0;
    animation: fadeIn @time 1 @time ease-in-out forwards;
    left: 27%;
  }
  .banner3 {
    width: 43.6%;
    bottom: 32%;
    left: 50%;
  }
  .banner3_2 {
    width: 43.6%;
    bottom: 32%;
    opacity: 0;
    left: 50%;
    animation: fadeIn @time 1 1.4s ease-in-out forwards;
  }
  .banner4 {
    width: 13.4%;
    bottom: 19%;
    left: 5%;
    // animation: slideDown 0.7s 1 ease-in-out;
  }
  .banner5 {
    width: 21.9%;
    bottom: 19%;
    left: 19.3%;
    // animation: slideUp 0.7s 1 ease-in-out;
  }
  .banner6 {
    width: 5%;
    bottom: 19%;
    left: 43%;
    // animation: slideUp 0.7s 1 ease-in-out;
  }
  .banner7 {
    width: 43.6%;
    bottom: 19%;
    left: 50%;
    // animation: slideUp 0.7s 1 ease-in-out;
  }
}
@keyframes fadeIn {
  0% {
    // transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    // transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    // transform: translateY(50%);
    opacity: 1;
  }
  100% {
    // transform: translateY(0%);
    opacity: 0;
  }
}

/* 核心 */
.core-function {
  position: relative;
  padding: 60px 0 42px;
  /deep/ .common-two-column_img {
    width: 49.1%;
    margin-top: -80px;
    margin-right: 93px;
  }
  /deep/ .common-two-column {
    column-gap: 24px;
  }
  .core-gift {
    position: absolute;
    width: 96px;
    bottom: -5%;
    left: 0;
  }
}

.core-function_main,
.application-scene_main {
  margin-top: 80px;
}

// 能力展示
.similar-main-wrap {
  padding: 80px 0;
  width: 100%;
  background: #f0f2f5;
  /deep/ .common-text {
    img {
      vertical-align: middle;
      display: inline-block;
      margin: -2px 3px 0;
      width: 24px;
    }
  }
  .operationBackgroundColor {
    height: calc(100% - 62px);
  }
  .similarityGraph {
    width: 32.8%;
    background: #d8d8d8;
    border-radius: 8px;
  }
  .operationCenter {
    width: 71.25%;
    height: auto;
    margin-left: 16px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: fit-content;
    }
  }
  .successImgProduct {
    width: 100%;
  }
  .operationYellowRight {
    flex: 1;
    flex-shrink: 0;
    height: 100%;
    margin: 7px 0 0 24px;
  }
  .recognitionResult {
    margin: 0;
    font-size: 18px;
    color: #000108;
    span {
      color: #ff8645;
    }
  }
  .detectionButton {
    background: rgba(108, 56, 224, 0.1);
    color: rgba(108, 56, 224, 1);
  }
  .confidenceLevel {
    margin: 24px 0 0;
    font-size: 14px;
  }
  .similarityGraphMain {
    height: 100%;
    justify-content: space-between;
    row-gap: 0.8%;
    column-gap: 0.8%;
  }
  .similarityGraphImg img {
    border-radius: 8px;
    overflow: hidden;
  }

  #similar-video {
    width: 100%;
    box-sizing: border-box;
    min-height: 14.4rem;
    border-radius: 0.426667rem;
    margin: auto;
  }

  .common-title {
    margin-bottom: 40px;
  }
}
.similar-main-cont {
  // display: flex;
  width: 100%;
  height: 770px;
  margin: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;
}

.copyright {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: #4e5969;
  font-weight: 400;
  margin-top: 8px;
}

.copyright-item span {
  font-weight: 600;
}

.copyright-pact .el-button {
  margin: unset;
  padding: unset;
  font-size: 14px;
  /* line-height: 18px; */
  color: #165dff;
  font-weight: 500;
}

/* 差异优势 */
.different-advantage-wrap {
  position: relative;
  overflow: hidden;
  background: linear-gradient(119deg, #503ad9 29%, #722ab8 127%);
  .different-advantage_main {
    margin-top: 50px;
  }
  .different-advantage {
    position: relative;
    padding-top: 80px;
    padding-bottom: 516px;
  }
  .diff-icon {
    position: absolute;
  }
  .diff-1 {
    width: 0.93%;
    right: 20%;
    top: 14%;
  }
  .diff-2 {
    width: 3.6%;
    left: 40%;
    bottom: 45%;
  }
  .diff-3 {
    width: 5.1%;
    top: 20%;
    left: 30%;
  }
  .diff-4 {
    width: 6.6%;
    top: 63%;
    left: 18%;
  }
  .diff-5 {
    width: 6.72%;
    top: 43%;
    right: 35%;
  }
  .diff-6 {
    width: 4.7%;
    top: 29%;
    right: 0%;
  }
  .diff-video {
    width: 355.4%;
    left: -120%;
    bottom: -42%;
  }
  /deep/ .common-two-column {
    column-gap: 55px;
  }
  /deep/ .common-two-column_img {
    width: 39.4%;
    margin-right: 85px;
    margin-top: -22px;
  }
  .different-clothes {
    width: 4.7%;
    margin-top: 50px;
  }
}
/* 应用场景 */
.application-scene {
  padding-top: 80px;
  padding-bottom: 55px;
}
.application-scene /deep/ .common-two-column {
  column-gap: 24px;
}
.application-scene /deep/ .common-two-column_img {
  width: 37.5%;
  margin-right: 148px;
  margin-top: -168px;
}
@media screen and(max-width: 990px) {
  .core-gift {
    display: none;
  }
}
@media screen and (min-width: 1440px) {
  .header-custom {
    width: 1440px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
