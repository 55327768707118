import request from "@/router/axios";
export const postSubmitForm = (data) => {
  // return request.post("/api/text/productTermsReplace",data)
  return request({
    url: "/api/text/productTermsReplace",
    method: "post",
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    data: {
      ...data,
    },
  });
};
export const getAllExportle = (params) => {
  return request.get("/api/text/exportTitleRelevance?batchId=" + params);
};
// export const apiUri = "http://192.168.48.36"
export const apiUri = "/api";
